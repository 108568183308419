import { Injectable, Inject, OnInit } from '@angular/core';
import { IBillingInfo, IPaymentInfo, ICartItem, IRegion } from './payment.model';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    constructor(@Inject(HttpClient) private http: HttpClient) {}

    productDetails(examcode: string, candidateId: string): Promise<ICartItem> {
        const url: string = `/umbraco/api/PaymentApi/GetProductDetails?examcode=${examcode}&candidateId=${candidateId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<ICartItem>(url, httpOptions).toPromise();
    }

    createCart(candidateId: string, examcode: string, sku: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/CreateCart?candidateId=${candidateId}&examcode=${examcode}&sku=${sku}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }

    loadCartItems(cartId: number): Promise<ICartItem[]> {
        const url: string = `/umbraco/api/PaymentApi/GetCartItems?cartId=${cartId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<ICartItem[]>(url, httpOptions).toPromise();
    }

    applyCoupon(cartId: number, couponCode: string): Promise<boolean> {
        const url: string = `/umbraco/api/PaymentApi/ApplyCoupon?cartId=${cartId}&couponCode=${couponCode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<boolean>(url, null, httpOptions).toPromise();
    }

    submitBilling(candidateId: string, cartId: number, billing: IBillingInfo): Promise<any> {
        const url: string = '/umbraco/api/PaymentApi/SubmitBilling';
        const json: string = JSON.stringify({
            CartId: cartId,
            CandidateId: candidateId,
            BillingFirstName: billing.FirstName,
            BillingLastName: billing.LastName,
            BillingStreet1: billing.Address1,
            BillingStreet2: billing.Address2,
            BillingCity: billing.City,
            BillingRegionId: billing.Region.Id,
            BillingRegionCode: billing.Region.Code,
            BillingRegionName: billing.Region.Name,
            BillingPostalCode: billing.PostalCode,
            BillingPhone: billing.Phone
        });
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, json, httpOptions).toPromise();
    }

    submitOrder(info:IPaymentInfo): Promise<number> {
        const url: string = '/umbraco/api/PaymentApi/SubmitOrder';
        const json: string = JSON.stringify({
            CartId: info.CartId,
            CreditCardType: info.CreditCardType,
            CardholderName: info.CardholderName,
            CreditCardNumber: info.CreditCardNumber,
            CreditCardExpMonth: info.CreditCardExpMonth,
            CreditCardExpYear: info.CreditCardExpYear,
            CreditCardCVC: info.CreditCardCVC,
            CreditCardZip: info.CreditCardZip
        });
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<number>(url, json, httpOptions).toPromise();
    }

    isRecert(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/IsRecert?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }
    isAda(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/IsADA?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }

    deferBilling(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/DeferBilling?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    deferAda(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/DeferADA?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    setPending(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/SetPending?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    recertify(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/Recertify?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }

    sendConfirmationEmail(candidateId: string, examcode: string, emailType: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/SendEmail?candidateId=${candidateId}&examBaseCode=${examcode}&emailType=${emailType}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }
    getPrometricScheduleLink(candidateId: string, examcode: string) {
        const url: string = `/umbraco/api/PrometricApi/ScheduleLinkTemplate?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(url, httpOptions).toPromise();
    }

    submitXelig(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PrometricApi/SubmitEligibility?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }

    confirmPayment(candidateId: string, examcode: string): Promise<any> {
        const url: string = `/umbraco/api/PaymentApi/UpdateBalance?candidateId=${candidateId}&examcode=${examcode}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(url, null, httpOptions).toPromise();
    }

    isBillingValid(billing: IBillingInfo): boolean {
        const isValid = billing.Address1 != "" && billing.FirstName != "" && billing.LastName != "" && billing.City != "" && billing.Region != null && billing.Region.Id > 0 && billing.PostalCode != "" && billing.Phone != "";
        return isValid;
    }

    getUSRegions(): IRegion[] {
        const regions: IRegion[] = [
            { Id: 1, Code: "AL", Name: "Alabama" },
            { Id: 2, Code: "AK", Name: "Alaska" },
            { Id: 3, Code: "AS", Name: "American Samoa" },
            { Id: 4, Code: "AZ", Name: "Arizona" },
            { Id: 5, Code: "AR", Name: "Arkansas" },
            { Id: 12, Code: "CA", Name: "California" },
            { Id: 13, Code: "CO", Name: "Colorado" },
            { Id: 14, Code: "CT", Name: "Connecticut" },
            { Id: 15, Code: "DE", Name: "Delaware" },
            { Id: 16, Code: "DC", Name: "District of Columbia" },
            { Id: 17, Code: "FM", Name: "Federated States Of Micronesia" },
            { Id: 18, Code: "FL", Name: "Florida" },
            { Id: 19, Code: "GA", Name: "Georgia" },
            { Id: 20, Code: "GU", Name: "Guam" },
            { Id: 21, Code: "HI", Name: "Hawaii" },
            { Id: 22, Code: "ID", Name: "Idaho" },
            { Id: 23, Code: "IL", Name: "Illinois" },
            { Id: 24, Code: "IN", Name: "Indiana" },
            { Id: 25, Code: "IA", Name: "Iowa" },
            { Id: 26, Code: "KS", Name: "Kansas" },
            { Id: 27, Code: "KY", Name: "Kentucky" },
            { Id: 28, Code: "LA", Name: "Louisiana" },
            { Id: 29, Code: "ME", Name: "Maine" },
            { Id: 30, Code: "MH", Name: "Marshall Islands" },
            { Id: 31, Code: "MD", Name: "Maryland" },
            { Id: 32, Code: "MA", Name: "Massachusetts" },
            { Id: 33, Code: "MI", Name: "Michigan" },
            { Id: 34, Code: "MN", Name: "Minnesota" },
            { Id: 35, Code: "MS", Name: "Mississippi" },
            { Id: 36, Code: "MO", Name: "Missouri" },
            { Id: 37, Code: "MT", Name: "Montana" },
            { Id: 38, Code: "NE", Name: "Nebraska" },
            { Id: 39, Code: "NV", Name: "Nevada" },
            { Id: 40, Code: "NH", Name: "New Hampshire" },
            { Id: 41, Code: "NJ", Name: "New Jersey" },
            { Id: 42, Code: "NM", Name: "New Mexico" },
            { Id: 43, Code: "NY", Name: "New York" },
            { Id: 44, Code: "NC", Name: "North Carolina" },
            { Id: 45, Code: "ND", Name: "North Dakota" },
            { Id: 46, Code: "MP", Name: "Northern Mariana Islands" },
            { Id: 47, Code: "OH", Name: "Ohio" },
            { Id: 48, Code: "OK", Name: "Oklahoma" },
            { Id: 49, Code: "OR", Name: "Oregon" },
            { Id: 50, Code: "PW", Name: "Palau" },
            { Id: 51, Code: "PA", Name: "Pennsylvania" },
            { Id: 52, Code: "PR", Name: "Puerto Rico" },
            { Id: 53, Code: "RI", Name: "Rhode Island" },
            { Id: 54, Code: "SC", Name: "South Carolina" },
            { Id: 55, Code: "SD", Name: "South Dakota" },
            { Id: 56, Code: "TN", Name: "Tennessee" },
            { Id: 57, Code: "TX", Name: "Texas" },
            { Id: 58, Code: "UT", Name: "Utah" },
            { Id: 59, Code: "VT", Name: "Vermont" },
            { Id: 60, Code: "VI", Name: "Virgin Islands" },
            { Id: 61, Code: "VA", Name: "Virginia" },
            { Id: 62, Code: "WA", Name: "Washington" },
            { Id: 63, Code: "WV", Name: "West Virginia" },
            { Id: 64, Code: "WI", Name: "Wisconsin" },
            { Id: 65, Code: "WY", Name: "Wyoming" }
        ];
        return regions;
    }
}