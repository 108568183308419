import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ExamPerformanceParameters, ExamPerformanceReport } from "./examperformancereport.model";

@Injectable({
    providedIn: 'root'
})
export class ExamPerformanceReportService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getReportData(startDate: Date | null, endDate: Date | null, certTypeId: number | null): Promise<ExamPerformanceReport[]> {
        const url: string = '/umbraco/api/ReportApi/ExamPerformanceReport';
        var params = {
            startDate: (startDate != null ? startDate.toJSON() : null),
            endDate: (endDate != null ? endDate.toJSON() : null),
            certTypeId: (certTypeId != null ? certTypeId.toString() : null)
        };
        return this.http.get<ExamPerformanceReport[]>(url, { params: params }).toPromise();
    }

    generatePDF(reportOptions: ExamPerformanceParameters) {
        console.log('generatePDF', reportOptions);
        const url: string = '/umbraco/api/ReportApi/GetExamPerformanceReportPDF';
        return this.http.post(url, reportOptions, { responseType: 'blob' }).toPromise();
    }

    generateXSLX(reportOptions: ExamPerformanceParameters) {
        console.log('generateXSLX', reportOptions);
        const url: string = '/umbraco/api/ReportApi/GetExamPerformanceReportXLSX';
        return this.http.post(url, reportOptions, { responseType: 'blob' }).toPromise();
    }

    getCertificationType(certTypeId: number | null): string {
        if (certTypeId == null) return "All";

        switch (certTypeId) {
            case 1: return "HCS-D";
            case 2: return "HCS-O";
            case 3: return "HCS-D";
            case 4: return "HCS-C";
            case 5: return "HCA";
            case 6: return "HOA";
            case 7: return "HHA";
            default: return "";
        }
    }

    sortReport(report: ExamPerformanceReport[], sortColumn: string, sortDirection: string): void {
        switch (sortColumn) {
            case 'CandidateId':
                report.sort((a, b) => {
                    let examA = `${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`;
                    let examB = `${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);


                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
            case 'FirstName':
                report.sort((a, b) => {
                    let examA = `${a.FirstName.trim()}|${a.LastName.trim()}|${a.CandidateId}`;
                    let examB = `${b.FirstName.trim()}|${b.LastName.trim()}|${b.CandidateId}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);

                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
            case 'LastName':
                report.sort((a, b) => {
                    let examA = `${a.LastName.trim()}|${a.FirstName.trim()}|${a.CandidateId}`;
                    let examB = `${b.LastName.trim()}|${b.FirstName.trim()}|${b.CandidateId}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);

                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
            case 'ExamType':
                report.sort((a, b) => {
                    let examA = `${a.ExamType}|${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`;
                    let examB = `${b.ExamType}|${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);

                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
            case 'DateTested':
                report.sort((a, b) => {
                    let examA = a.DateTested == null
                        ? `1970-01-01T00:00:00|${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`
                        : `${a.DateTested}|${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`;
                    let examB = b.DateTested == null
                        ? `1970-01-01T00:00:00|${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`
                        : `${b.DateTested}|${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);

                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
            case 'OverallTestScore':
                report.sort((a, b) => {
                    let examA = a.OverallTestScore == null
                        ? `      |${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`
                        : `${a.OverallTestScore.toFixed(2).toString().padStart(6, '0')}|${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`;
                    let examB = b.OverallTestScore == null
                        ? `      |${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`
                        : `${b.OverallTestScore.toFixed(2).toString().padStart(6, '0')}|${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);

                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
            case 'Grade':
                report.sort((a, b) => {
                    let examA = `${a.Grade}|${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`;
                    let examB = `${b.Grade}|${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);

                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
            case 'TestAttempt':
                report.sort((a, b) => {
                    if (a.TestAttempt < b.TestAttempt) return (sortDirection == "ASC" ? -1 : 1);
                    if (a.TestAttempt > b.TestAttempt) return (sortDirection == "ASC" ? 1 : -1);

                    let examA = `${a.CandidateId}|${a.LastName.trim()}|${a.FirstName.trim()}`;
                    let examB = `${b.CandidateId}|${b.LastName.trim()}|${b.FirstName.trim()}`;
                    if (examA < examB) return (sortDirection == "ASC" ? -1 : 1);
                    if (examA > examB) return (sortDirection == "ASC" ? 1 : -1);
                    return 0;
                });
                break;
        }
    }
}