//import 'chart.js';
import { Inject, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from "../shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { createCustomElement } from '@angular/elements';
import { BaseChartDirective, ChartsModule } from 'ng2-charts';
import ChartLabels from 'chartjs-plugin-datalabels';
import { ExamPerformanceReportComponent } from './examperformancereport.component';
import { ExamPerformanceReportService } from './examperformancereport.service';
import { ExamPerformanceReportFilterPipe } from './examperformancereportfilter.pipe';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        NgbModule,
        ChartsModule
    ],
    providers: [
        ExamPerformanceReportService
    ],
    declarations: [
        ExamPerformanceReportComponent,
        ExamPerformanceReportFilterPipe
    ],
    entryComponents: [
        ExamPerformanceReportComponent
    ],
    bootstrap: [
        ExamPerformanceReportComponent
    ]
})
export class ExamPerformanceReportModule {
    constructor(@Inject(Injector) injector: Injector) {
        BaseChartDirective.unregisterPlugin(ChartLabels);

        const examPerformanceReportComponent = createCustomElement(ExamPerformanceReportComponent, { injector: injector });
        customElements.define('exam-performance-report-component', examPerformanceReportComponent);
    }

    ngDoBootstrap() {
    }
}