import { Component, OnInit, Inject, ViewChild, ElementRef, ViewChildren } from "@angular/core";
import { NgbDateAdapter, NgbDateParserFormatter, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateAdapter, CustomDateParserFormatter } from "../shared/ngbDateParsers";
import examPerformanceTemplate from './examperformancereport.component.html';
import { ExamPerformanceParameters, ExamPerformanceReport, ExamPieData } from "./examperformancereport.model";
import { ExamPerformanceReportService } from "./examperformancereport.service";
import { ChartOptions, ChartData, ChartType, ChartDataSets, ChartPluginsOptions, ChartConfiguration } from 'chart.js';
import { QueryList } from "@angular/core";
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'exam-performance-report-component',
    template: examPerformanceTemplate,
    providers: [
        { provide: NgbDateAdapter, useClass: CustomDateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class ExamPerformanceReportComponent implements OnInit {
    startDate: Date | null = null;
    endDate: Date | null = null;
    exams: ExamPerformanceReport[] = [];
    hcsdExams: ExamPerformanceReport[] = [];
    hcshExams: ExamPerformanceReport[] = [];
    hcsoExams: ExamPerformanceReport[] = [];
    hcsdPies: ExamPieData[] = [];
    hcshPies: ExamPieData[] = [];
    hcsoPies: ExamPieData[] = [];
    chartPlugins = [pluginDataLabels];
    reportStartDate: Date | null = null;
    reportEndDate: Date | null;
    displayResults: boolean = true;
    hcsdFilterText: string = '';
    hcshFilterText: string = '';
    hcsoFilterText: string = '';
    hcsdSortColumn: string = 'CandidateId';
    hcshSortColumn: string = 'CandidateId';
    hcsoSortColumn: string = 'CandidateId';
    hcsdSortDirection: string = 'ASC';
    hcshSortDirection: string = 'ASC';
    hcsoSortDirection: string = 'ASC';

    constructor(@Inject(ExamPerformanceReportService) private service: ExamPerformanceReportService, @Inject(NgbCalendar) private calendar: NgbCalendar) {
    }

    ngOnInit() {
        this.reset();
    }

    getReportData() {
        this.clearResults();

        this.service.getReportData(this.startDate, this.endDate, null).then(data => {
            this.hcsdFilterText = '';
            this.hcshFilterText = '';
            this.hcsoFilterText = '';
            this.reportStartDate = this.startDate == null ? null : new Date(this.startDate);
            this.reportEndDate = this.endDate == null ? null : new Date(this.endDate);
            this.exams = data;

            this.hcsdExams = this.exams.filter(e => e.CertificationType == 'HCS-D');
            this.hcshExams = this.exams.filter(e => e.CertificationType == 'HCS-H');
            this.hcsoExams = this.exams.filter(e => e.CertificationType == 'HCS-O');

            this.createHCSDCharts();
            this.createHCSHCharts();
            this.createHCSOCharts();

            this.displayResults = true;
        });
    }

    reset() {
        this.endDate = null;
        this.startDate = null;
        this.reportStartDate = null;
        this.reportEndDate = null;

        this.clearResults();
    }

    clearResults() {
        this.exams = [];
        this.hcsdExams = [];
        this.hcshExams = [];
        this.hcsoExams = [];
        this.hcsdPies = [];
        this.hcshPies = [];
        this.hcsoPies = [];
        this.displayResults = false;
    }

    createHCSDCharts() {
        this.createCharts('HCS-D', this.hcsdExams, this.hcsdPies);
    }

    createHCSHCharts() {
        this.createCharts('HCS-H', this.hcshExams, this.hcshPies);
    }

    createHCSOCharts() {
        this.createCharts('HCS-O', this.hcsoExams, this.hcsoPies);
    }

    createCharts(certType: string, report: ExamPerformanceReport[], pies: ExamPieData[]) {
        let testCount: number = 1;
        let examCount: number = report == null ? 0 : Number(report.length);

        do {
            let passData = report == null ? [] : report.filter(e => e.Grade == "Pass" && e.TestAttempt == testCount);
            let failData = report == null ? [] : report.filter(e => e.Grade == "Fail" && e.TestAttempt == testCount);
            let passCount = Number(passData.length);
            let failCount = Number(failData.length);
            let title: string = `${certType} Exam Attempt #${testCount}`;

            let pie: ExamPieData = {
                CertificationType: certType,
                TestAttempt: testCount,
                PassCount: passCount,
                FailCount: failCount,
                PieHeader: title,
                PieDataSet: [{
                    data: [passCount, failCount],
                    label: title,
                    backgroundColor: ['#008800', '#880000']
                }],
                PieOptions: {
                    responsive: true,
                    legend: {
                        position: 'bottom'
                    },
                    title: {
                        display: true,
                        text: title
                    },
                    aspectRatio: 1.5,
                    plugins: {
                        datalabels: {
                            color: 'black',
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    }
                                },
                                value: {
                                    color: 'navy'
                                }
                            },
                            formatter: (value, ctx) => {
                                let sum = 0;
                                let dataArr = ctx.chart.data.datasets[0].data;
                                dataArr.map(data => {
                                    sum += data;
                                });
                                let percentage = (value * 100 / sum).toFixed(2) + "%";
                                return percentage;
                            }
                        }
                    }
                }
            }
            pies.push(pie);

            examCount = examCount - passCount - failCount;
            console.log(`${certType} Pie #${testCount}: ${pie.PassCount} Passed, ${pie.FailCount} Failed, [${examCount} to process]`);
            testCount = testCount + 1;
        }
        while (examCount > 0);
    }

    generatePDF(certTypeId: number) {
        const reportOptions: ExamPerformanceParameters = {
            StartDate: this.reportStartDate,
            EndDate: this.reportEndDate,
            CertificationTypeId: certTypeId
        };
        this.service.generatePDF(reportOptions).then(file => {
            const blob = new Blob([file], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            const certType = this.service.getCertificationType(certTypeId);
            const downloadLink: HTMLAnchorElement = document.createElement('a');
            downloadLink.download = `${certType}ExamPerformanceReport.pdf`;
            downloadLink.href = url;
            downloadLink.click();
            downloadLink.remove();

            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 100);
        }).catch(error => {
            console.error('Error generating PDF', error);
        });
    }

    generateXSLX(certTypeId: number) {
        const reportOptions: ExamPerformanceParameters = {
            StartDate: this.reportStartDate,
            EndDate: this.reportEndDate,
            CertificationTypeId: certTypeId
        };
        this.service.generateXSLX(reportOptions).then(file => {
            const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);

            const certType = this.service.getCertificationType(certTypeId);
            const downloadLink: HTMLAnchorElement = document.createElement('a');
            downloadLink.download = `${certType}ExamPerformanceReport.xlsx`;
            downloadLink.href = url;
            downloadLink.click();
            downloadLink.remove();

            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 100);
        }).catch(error => {
            console.error('Error generating PDF', error);
        });
    }

    sortTable(certType: string, sortColumn: string, sortDirection: string) {
        switch (certType) {
            case 'HCS-D':
                if (this.hcsdSortColumn == sortColumn) {
                    this.hcsdSortDirection = sortDirection == 'ASC' ? 'DESC' : 'ASC';
                }
                else {
                    this.hcsdSortColumn = sortColumn;
                }
                this.service.sortReport(this.hcsdExams, this.hcsdSortColumn, this.hcsdSortDirection)
                break;
            case 'HCS-H':
                if (this.hcshSortColumn == sortColumn) {
                    this.hcshSortDirection = sortDirection == 'ASC' ? 'DESC' : 'ASC';
                }
                else {
                    this.hcshSortColumn = sortColumn;
                }
                this.service.sortReport(this.hcshExams, this.hcshSortColumn, this.hcshSortDirection)
                break;
            case 'HCS-O':
                if (this.hcsoSortColumn == sortColumn) {
                    this.hcsoSortDirection = sortDirection == 'ASC' ? 'DESC' : 'ASC';
                }
                else {
                    this.hcsoSortColumn = sortColumn;
                }
                this.service.sortReport(this.hcsoExams, this.hcsoSortColumn, this.hcsoSortDirection)
                break;
        }
    }
}