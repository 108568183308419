import { Pipe, PipeTransform } from '@angular/core';
import { ExamPerformanceReport } from './examperformancereport.model';
@Pipe({
    name: 'examPerformanceReportFilter'
})
export class ExamPerformanceReportFilterPipe implements PipeTransform {
    transform(exams: ExamPerformanceReport[], searchText: string): any {
        if (!exams) return null;
        if (!searchText) return exams;

        searchText = searchText.toLowerCase().normalize('NFC');

        return exams.filter(function (exam: ExamPerformanceReport) {
            let searchDate = new Date(searchText).toString();
            let searchNumber = Number(searchText);

            if (searchDate == "Invalid Date" && isNaN(searchNumber)) {
                //text search
                return exam.CandidateId.toLowerCase().normalize('NFC').search(searchText) >= 0 ||
                    exam.FirstName.toLowerCase().normalize('NFC').search(searchText) >= 0 ||
                    exam.LastName.toLowerCase().normalize('NFC').search(searchText) >= 0 ||
                    exam.ExamType.toLowerCase().normalize('NFC').search(searchText) >= 0 ||
                    exam.Grade.toLowerCase().normalize('NFC').search(searchText) >= 0;
            }
            else if (searchDate != "Invalid Date" && isNaN(searchNumber)) {
                //date search
                let testDate = new Date(exam.DateTested).toString();
                return testDate != "Invalid Date" && testDate == searchDate;
            }
            else {
                //number search
                let regExSearch = searchText.replace('.', '[.]');
                let candidateId = exam.CandidateId.toLowerCase().normalize('NFC');
                let firstName = exam.FirstName.toLowerCase().normalize('NFC');
                let lastName = exam.LastName.toLowerCase().normalize('NFC');

                return candidateId.search(regExSearch) >= 0 ||
                        firstName.search(regExSearch) >= 0 ||
                        lastName.search(regExSearch) >= 0 ||
                        exam.OverallTestScore == searchNumber ||
                        exam.TestAttempt == searchNumber;
            }
        });
    }
}